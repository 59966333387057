<template>
  <div>
    <!-- 工商入户安检表具信息 -->
    <div class="content1">
      <div class="all">
        <div ref="SearchBar" class="SearchBar" style="padding-left: 20px">
           <el-form  >
          燃气表类型:
          <el-select v-model="searchBox.tableType" placeholder="请选择" size="mini" clearable filterable @change="search">
                    <el-option
                      v-for="(item, index) in tableTypeList"
                      :key="index"
                      :label="item.name"
                      :value="item.id"
                    ></el-option>
                  </el-select>
         <span style="padding-left:20px;">表向:</span>
          <el-select v-model="searchBox.tableDirection" placeholder="请选择" size="mini" clearable filterable @change="search">
                    <el-option
                      v-for="(item, index) in tableDirectionList"
                      :key="index"
                      :label="item.name"
                      :value="item.id"
                    ></el-option>
                  </el-select>
          <span style="padding-left:20px;">燃气表厂家:</span>
          <el-select v-model="searchBox.tableVender" placeholder="请选择" size="mini" clearable filterable @change="search">
                    <el-option
                      v-for="(item, index) in tableVenderList"
                      :key="index"
                      :label="item.name"
                      :value="item.id"
                    ></el-option>
                  </el-select>
         <span style="padding-left:20px;"> 启用状态:</span>
          <el-select v-model="searchBox.generalStatus" placeholder="请选择" size="mini" clearable filterable @change="search">
                    <el-option
                      v-for="(item, index) in typeList"
                      :key="index"
                      :label="item.name"
                      :value="item.id"
                    ></el-option>
                  </el-select>  
           <span style="padding-left:20px;">表编号:</span>
            <el-input style="width: 150px" placeholder="请输入" size="small"  v-model="searchBox.sheetSteel"></el-input>
            <el-button type="primary" class="SearchBtn" size="small" @click="search">查询</el-button>
            <el-button type="warning" class="SearchBtn" size="small" @click="resetData">重置</el-button>
           </el-form> 
        </div>
        <div class="table" >
          <div style="display:flex;justify-content:flex-end">
            <el-button  class="SearchBtn" size="small" @click="addDerive" style="border: 1px solid #1082FF;color: #1082FF;" v-if="$anthButtons.getButtonAuth('biaojutianjia')">添加</el-button>
            <!-- <el-button  class="SearchBtn" size="small" @click="downloadempty">导出空白模板</el-button>
            <el-button  class="SearchBtn" size="small" @click="deriveExcel">导入</el-button> -->
          </div>
          <!--数据表格-->
          <el-table
              ref="tableData"
              :data="tableData"
              style="width: 100%;margin: 10px 0;"
              border
               height="calc(100vh - 384px)"
              :stripe="true"
              :default-sort = "{prop: 'createTime', order: 'descending'}">
            <el-table-column type="index" label="序号" width="70" align="center"  :index="((searchBox.current-1)*searchBox.size)+1"> </el-table-column>
            <el-table-column prop="sheetSteel" label="表编号" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="tableTypeName" label="燃气表类型" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="tableDirectionName" label="表向" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="tableVenderName" label="燃气表厂家" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="tableTitles" label="表封号" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="openDate" label="开户日期" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="receiveStove" label="所接炉具" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="purchasedVolume" label="所购气量" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="recentlyDate" label="最近购气日期" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="remark" label="备注" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="realname" label="启用状态" align="center">
                <template slot-scope="{row}">
                  <el-tag v-if="row.generalStatus==0" type="danger">禁用</el-tag>
                  <el-tag v-if="row.generalStatus==1" type="success">启用</el-tag>
                </template>
             </el-table-column>
            <el-table-column label="操作" align="center" show-overflow-tooltip width="170">
              <!-- <template slot-scope="{row}">
                <el-button type="primary"  size="small" @click="seeDetil(row)">查看</el-button>
              </template> -->
              <template slot-scope="{ row }">
                <div class="TableActionBar">
                  <el-button
                      v-if="$anthButtons.getButtonAuth('biaojubianji')"
                    type="primary"
                    size="mini"
                    style="margin: 0 10px"
                    @click="compile(row)"
                    >编辑</el-button
                  >
                 <el-dropdown   @command="(command) => exchange(command,row)">
                  <el-button
                    type="text"
                    size="mini"
                    style="border: 1px solid #1082FF;color: #1082FF;padding:0 11px;height:30px;"
                    >更多<i class="el-icon-caret-bottom el-icon--right"></i></el-button
                  >
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="换表" v-if="$anthButtons.getButtonAuth('biaojuhuanbiao')">换表</el-dropdown-item>
                    <el-dropdown-item command="停用/启用" v-if="$anthButtons.getButtonAuth('biaojuqiyongtingyong')">停用/启用</el-dropdown-item>
                    <el-dropdown-item command="删除" v-if="$anthButtons.getButtonAuth('biaojushanchu')">删除</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="pageBox">
            <el-pagination
                :current-page="searchBox.current"
                :background="true"
                :page-sizes="[30, 50, 100]"
                :page-size="searchBox.size"
                layout="total, prev, pager, next, sizes, jumper"
                :total="total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
     <!-- 编辑 -->
    <el-dialog
      title="编辑表具信息"
      :visible.sync="addUserDialog"
      width="520px"
      :close-on-click-modal="false"
      :before-close="UserClose">
      <el-form class="SearchBox" ref="from" :model="from" :rules="rule">
            <el-form-item label="表编号：" label-width="145px" prop="sheetSteel">
              <el-input v-model="from.sheetSteel" placeholder="请输入内容" style="width:308px" :maxlength='30'></el-input>
            </el-form-item>
            <el-form-item label="燃气表类型：" label-width="145px" prop="tableType">
              <el-select v-model="from.tableType" placeholder="请选择" filterable style="width:308px">
                    <el-option
                        v-for="(item, index) in tableTypeList"
                        :key="index"
                        :label="item.name"
                        :value="item.id"
                    ></el-option>
                  </el-select>
            </el-form-item>
            <el-form-item label="表向：" label-width="145px" prop="tableDirection" >
              <el-select v-model="from.tableDirection" placeholder="请选择" filterable style="width:308px">
                <el-option
                    v-for="(item, index) in tableDirectionList"
                    :key="index"
                    :label="item.name"
                    :value="item.id"
                ></el-option>
                  </el-select>
            </el-form-item>
            <el-form-item label="燃气表厂家：" label-width="145px" prop="tableVender">
              <el-select v-model="from.tableVender" placeholder="请选择" filterable style="width:308px">
                <el-option
                    v-for="(item, index) in tableVenderList"
                    :key="index"
                    :label="item.name"
                    :value="item.id"
                ></el-option>
                  </el-select>
            </el-form-item>
            <el-form-item label="表封号：" label-width="145px">
              <el-input v-model="from.tableTitles" placeholder="请输入内容" style="width:308px" prop="input" :maxlength='30'></el-input>
            </el-form-item>
            <el-form-item label="所接炉具：" label-width="145px" prop="receiveStove">
              <el-input v-model="from.receiveStove" placeholder="请输入内容" style="width:308px" prop="input" :maxlength='30'></el-input>
            </el-form-item>
            <el-form-item label="开户日期：" label-width="145px" prop="openDate">
              <el-date-picker
                  style="width:308px"
                  v-model="from.openDate"
                  type="datetime"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="选择开户日期" >
              </el-date-picker>
            </el-form-item>
            <!-- <el-form-item label="对接系统：" label-width="145px" prop="tableSystem">
              <el-radio-group v-model="from.tableSystem">
                <el-radio :label="1">卡表系统</el-radio>
                <el-radio :label="2">营收系统</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="识别号："  label-width="145px" >
              <el-input v-model="from.identifyNumber"  placeholder="请输入内容"  style="width:308px" :maxlength='30'></el-input>
            </el-form-item> -->
            <el-form-item label="备注：" label-width="145px" prop="remark">
              <el-input v-model="from.remark"  placeholder="请输入备注"  style="width:308px" :maxlength='30'></el-input>
            </el-form-item>
          </el-form>
      <span slot="footer" class="dialog-footer">
          <el-button style="margin-right: 10px" @click="addUserDialog = false">取消</el-button>
          <el-button type="primary" @click="showTable('from')">确定</el-button>
      </span>
    </el-dialog>
    <!-- 换表 -->
    <el-dialog
      title="换表"
      :close-on-click-modal="false"
      :visible.sync="exchangeDialog"
      width="520px"
      @close="exchangeClose">
      <el-form class="SearchBox" ref="exchangeFrom" :model="exchangefrom" :rules="exchangerule">
            <el-form-item label="旧表机械数：" label-width="145px" prop="oldMechanicalData">
              <el-input v-model="exchangefrom.oldMechanicalData" placeholder="请输入内容" style="width:308px" :maxlength='30'></el-input>
            </el-form-item>
            <el-form-item label="新表机械数：" label-width="145px" prop="newMechanicalData">
              <el-input v-model="exchangefrom.newMechanicalData" placeholder="请输入内容" style="width:308px" :maxlength='30'></el-input>
            </el-form-item>
            <el-form-item label="表编号：" label-width="145px" prop="sheetSteel" >
              <el-input v-model="exchangefrom.sheetSteel" placeholder="请输入内容" style="width:308px" :maxlength='30'></el-input>
            </el-form-item>
        <el-form-item label="燃气表类型：" label-width="145px" prop="tableType">
          <el-select v-model="exchangefrom.tableType" placeholder="请选择" filterable style="width:308px">
            <el-option
                v-for="(item, index) in tableTypeList"
                :key="index"
                :label="item.name"
                :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="表向：" label-width="145px" prop="tableDirection" >
          <el-select v-model="exchangefrom.tableDirection" placeholder="请选择" filterable style="width:308px">
            <el-option
                v-for="(item, index) in tableDirectionList"
                :key="index"
                :label="item.name"
                :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="燃气表厂家：" label-width="145px" prop="tableVender">
          <el-select v-model="exchangefrom.tableVender" placeholder="请选择" filterable style="width:308px">
            <el-option
                v-for="(item, index) in tableVenderList"
                :key="index"
                :label="item.name"
                :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
            <el-form-item label="表封号：" label-width="145px" prop="tableTitles">
              <el-input v-model="exchangefrom.tableTitles" placeholder="请输入"  style="width:308px" :maxlength='30'></el-input>
            </el-form-item>
            <el-form-item label="剩余气量："  label-width="145px" prop="residualVolume" >
              <el-input v-model="exchangefrom.residualVolume" placeholder="请输入"  style="width:308px" :maxlength='30'></el-input>
            </el-form-item>
            <el-form-item label="换表人：" label-width="145px" prop="updateTableBy">
              <el-input v-model="exchangefrom.updateTableBy" placeholder="请输入"  style="width:308px" :maxlength='30'></el-input>
            </el-form-item>
            <el-form-item label="换表日期：" label-width="145px" prop="updateTableTime">
              <el-date-picker
                v-model="exchangefrom.updateTableTime"
                type="date"
                value-format="yyyy-MM-dd"
                style="width:308px"
                placeholder="选择日期">
              </el-date-picker>
            </el-form-item>
          </el-form>
      <span slot="footer" class="dialog-footer">
          <el-button style="margin-right: 10px" @click="exchangeDialog = false">取消</el-button>
          <el-button type="primary" @click="submitExchange('exchangeFrom')">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>


<script>
import {tableWithPageList, tableWithReplace, tableWithUpdate,tableWithDelete,tableWithUpdateGeneralStatus} from '../../../RequestPort/intranet/index'
import {selectDictListByPid} from "../../../RequestPort/business/userFile";
export default {
  name: "lockout",
  data(){
    return{
      // 查询条件
      searchBox:{
        current:1,
        size: 100,
        fileSource:1
      },
      total:0,
      // 查询
      typeList:[
          {
              name:'启用',
              id:1
          },
          {
            name:'禁用',
            id:0
          }
      ],
      tableTypeList:[],
      tableDirectionList:[],
      tableVenderList:[],
      // 列表数据
      tableData:[{}],
      form:{

      },
      rule:{
        sheetSteel:{ required: true, message: "请输入", trigger: "change" },
        tableType:{ required: true, message: "请选择", trigger: "change" },
        tableDirection:{ required: true, message: "请选择", trigger: "change" },
        tableVender:{ required: true, message: "请选择", trigger: "change" },
        receiveStove:{ required: true, message: "请输入", trigger: "change" },
        identifyNumber:{ required: true, message: "请输入", trigger: "change" },
        // tableSystem:{ required: true, message: "请选择", trigger: "change" },
        //openDate:{ required: true, message: "请选择", trigger: "change" },
      },
      exchangerule:{
        residualVolume:{ required: true, message: "请输入", trigger: "change" },
        oldMechanicalData:{ required: true, message: "请输入", trigger: "change" },
        newMechanicalData:{ required: true, message: "请输入", trigger: "change" },
        sheetSteel:{ required: true, message: "请输入", trigger: "change" },
        tableType:{ required: true, message: "请选择", trigger: "change" },
        tableDirection:{ required: true, message: "请选择", trigger: "change" },
        tableVender:{ required: true, message: "请选择", trigger: "change" },
        updateTableBy:{ required: true, message: "请输入", trigger: "change" },
        updateTableTime:{ required: true, message: "请输入", trigger: "change" }
      },
      // 编辑弹窗
      addUserDialog:false,
      // 编辑对象
      from:{
        // tableSystem:1
      },
      // 换表弹窗
      exchangeDialog:false,
      exchangefrom: {
        residualVolume:'',
        oldMechanicalData:'',
        newMechanicalData:0,
        sheetSteel:'',
        tableType:'',
        tableDirection:'',
        tableVender:'',
        tableTitles:'',
        updateTableBy:'',
        updateTableTime:'',
      }
    }
  },
  mounted() {
    this.getOption()
    this.loadList(this.searchBox)
  },
  methods:{
    submitExchange(exchangeFrom){
      this.$refs[exchangeFrom].validate((valid) => {
        if (!valid) return false
        tableWithReplace(this.exchangefrom).then(res=>{
          if(res.code===200){
            this.exchangeDialog = false
            this.$message.success(res.data)
            this.loadList(this.searchBox)
          }else {
            this.$message.error(res.data)
          }
        })

      })
    },
    showTable(form){
      this.$refs[form].validate((valid) => {
        if (!valid) return false
        this.from.userId = this.$route.query.id
        tableWithUpdate(this.from).then(res=>{
         if(res.code===200){
           this.addUserDialog = false
           this.$message.success(res.data)
           this.loadList(this.searchBox)
         }else {
           this.$message.error(res.data)
         }
        })
      })
    },
    getOption(){
      selectDictListByPid({pid: 707}).then(res => {
        this.tableDirectionList = res.data
      })
      selectDictListByPid({pid: 702}).then(res => {
          this.tableTypeList = res.data
      })
      selectDictListByPid({pid: 710}).then(res => {
        this.tableVenderList = res.data
      })
    },
    /**
     * 换表
     */
    exchange(command,row){
      if(command == '换表') {
        this.exchangeDialog = true
        this.exchangefrom.pid = row.id
        var myDate = new Date();
        var month = parseInt(myDate.getMonth())+1;
        month = (Array(2).join(0) + month).slice(-2)
        var date = myDate.getFullYear()+"-"+month+"-"+myDate.getDate() + ' 00:00:00';
        this.exchangefrom.updateTableTime = date
      }
      if(command == '删除') {
        this.$confirm('是否确认删除', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal:false,
        type: 'warning'
        }).then(() => {
          tableWithDelete({ tableWithId: row.id }).then(e => {
            if (e.code === 200) {
              this.$message.success(e.data)
              this.loadList(this.searchBox)
            } else {
              this.$message.error(e.data)
            }
          })
        })
      }
      if(command == '停用/启用') {
       let str =  row.generalStatus==1?'是否禁用':'是否启用'
        this.$confirm(str, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          closeOnClickModal:false,
          type: 'warning'
        }).then(() => {
          tableWithUpdateGeneralStatus({ tableWithId: row.id }).then(e => {
            if (e.code === 200) {
              this.$message.success(e.data)
              this.loadList(this.searchBox)
            } else {
              this.$message.error(e.data)
            }
          })
        })
      }
    },
    /**
     * 换表关闭事件
     */
    exchangeClose(){
      this.exchangefrom = {
        residualVolume:'',
            oldMechanicalData:'',
            newMechanicalData:0,
            sheetSteel:'',
            tableType:'',
            tableDirection:'',
            tableVender:'',
            tableTitles:'',
            updateTableBy:'',
            updateTableTime:'',
      }
      this.$refs.exchangeFrom.resetFields()
      this.exchangeDialog = false
    },
    /**
     * 编辑
     */
    compile(row){
      this.addUserDialog = true
      let obj = JSON.parse(JSON.stringify(row))
      this.from = obj
      this.from.tableType = obj.tableType+''
      this.from.tableDirection = obj.tableDirection+''
      this.from.tableVender = obj.tableVender+''
    },
    /**
     * 关闭弹窗
     */
    UserClose(){
      this.addUserDialog = false
    },
    // 添加
    addDerive(){
       this.$router.push({ path: '/home/addTable', query: { id: this.$route.query.id} })
    },
    /**
     * 更换条数
     */
    handleSizeChange(val){
      this.searchBox.current = 1
      this.searchBox.size = val
      this.loadList(this.searchBox)
    },
    /**
     * 更换页码
     */
    handleCurrentChange(val){
      this.searchBox.current = val
      this.loadList()
    },
    /**
     * 查询
     */
    search(){
      this.searchBox.current = 1
      this.loadList(this.searchBox)
    },
    /**
     * 获取列表
     */
    loadList(obj){
        if(obj){
          this.searchBox.current = 1
        }
        this.searchBox.userId = this.$route.query.id
      tableWithPageList(this.searchBox).then(res=>{
          this.tableData = res.data.records;
          this.total = res.data.total;
        })
    },
    /**
     * 重置
     */
    resetData() {
      this.searchBox={
        current:1,
        size: 100,
      }
      this.loadList(1)
    },
  }
}
</script>

<style lang="less" scoped>
::v-deep .el-upload--picture-card{width:100px;height:100px;line-height:100px}
::v-deep .el-upload-list--picture-card .el-upload-list__item{width:100px;height:100px;line-height:100px}
.detil-code{
  padding-left: 10px;
  font-size: 16px;
  height: 26px;
  line-height: 16px;
  color: #000000;
  position: relative;
}
.detil-code:before{
  content: '';
  position: absolute;
  width: 3px;
  height: 16px;
  background-color: #007AFF;
  top: 0;
  left: 0;
}
.content {
  background: #f3f4f8;
  width: 100%;
  border-radius: 6px;
  padding: 10px;
}
.SearchBar {
  /* border: 1px solid #ecedf1; */
  border-radius: 5px;
  margin: 10px;
  padding-top: 1%;
  padding-bottom: 1%;
  background-color: #ffffff;
}
.SearchBtn{
  margin-left: 10px;
}
.table {
  padding: 10px;
  border-radius: 5px;
  margin: 10px;
  background-color: #ffffff;
}
</style>